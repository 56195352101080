export const PLANS = Object.freeze([
  {
    tier: 0,
    name: 'start',
    apps: 'oneApp',
    integration: 'oneIntegration',
    price: 'freeWithLimit',
  },
  {
    tier: 1,
    name: 'essential',
    apps: 'threeApps',
    integration: 'threeIntegrations',
    price: 'tierOnePrice',
  },
  {
    tier: 2,
    name: 'complete',
    apps: 'allApps',
    integration: 'allIntegrations',
    price: 'tierTwoPrice',
  },
  {
    tier: 3,
    name: 'genius',
    apps: 'customApps',
    integration: 'customWorkflows',
    price: 'tierThreePrice',
  },
]);
