<i18n lang="yaml">
pt:
  start: 'Start'
  essential: 'Essential'
  complete: 'Complete'
  genius: 'Genius'
  availableApps: 'Apps disponíveis'
  oneApp: '1 app'
  threeApps: '3 apps'
  allApps: 'Todos os apps da loja'
  customApps: 'Todos da loja e customizados'
  availableIntegrations: 'Integrações disponíveis'
  oneIntegration: '1 integração'
  threeIntegrations: '3 integrações'
  allIntegrations: 'Todas as integrações disponíveis'
  customWorkflows: 'Integrações disponíveis e customizadas'
  price: 'Preço por colaborador'
  freeWithLimit: 'Grátis (até 100 colaboradores)'
  tierOnePrice: 'R$ 9 / mês'
  tierTwoPrice: 'R$ 19 / mês'
  tierThreePrice: 'R$ 29 / mês'
  currentPlan: 'Plano atual'
  upgrade: 'Fazer upgrade'
  downgrade: 'Selecionar plano'
en:
  start: 'Start'
  essential: 'Essential'
  complete: 'Complete'
  genius: 'Genius'
  availableApps: 'Available apps'
  oneApp: '1 app'
  threeApps: '3 apps'
  allApps: 'All apps in the app store'
  customApps: 'All app store and custom apps'
  availableIntegrations: 'Available integrations'
  oneIntegration: '1 integration'
  threeIntegrations: '3 integrations'
  allIntegrations: 'All available integrations'
  customWorkflows: 'Install and customize workflows'
  price: 'Price per user'
  freeWithLimit: 'Free (up to 100 users)'
  tierOnePrice: 'R$ 9 / month'
  tierTwoPrice: 'R$ 19 / month'
  tierThreePrice: 'R$ 29 / month'
  currentPlan: 'Current plan'
  upgrade: 'Upgrade'
  downgrade: 'Downgrade'
</i18n>

<template>
  <v-table class="pricing-table">
    <thead>
      <tr>
        <th class="text-left">
          {{ 'Zazos' }}
        </th>

        <th
          v-for="plan in PLANS"
          :key="plan.name"
          background-color="primary"
        >
          <div class="pricing-table__plan-text">
            {{ t(plan.name) }}
          </div>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td>{{ t('availableApps') }}</td>
        <td
          v-for="plan in PLANS"
          :key="plan.name"
        >
          <div class="pricing-table__plan-text">
            {{ t(plan.apps) }}
          </div>
        </td>
      </tr>

      <tr>
        <td>{{ t('availableIntegrations') }}</td>
        <td
          v-for="plan in PLANS"
          :key="plan.name"
        >
          <div class="pricing-table__plan-text">
            {{ t(plan.integration) }}
          </div>
        </td>
      </tr>

      <tr class="pricing-table__price-row">
        <td>
          <strong>{{ t('price') }}</strong>
        </td>

        <td
          v-for="plan in PLANS"
          :key="plan.name"
        >
          <div class="pricing-table__plan-text">
            <strong>{{ t(plan.price) }}</strong>
          </div>
        </td>
      </tr>

      <tr>
        <td />
        <td
          v-for="plan in PLANS"
          :key="plan.name"
        >
          <div
            v-if="currentPlan?.tier === plan.tier"
            class="ml-2"
          >
            <strong>{{ t('currentPlan') }}</strong>
          </div>

          <deck-button
            v-else-if="currentPlan?.tier < plan.tier"
            size="small"
            class="pricing-table__plan-button"
            @click="ctaClick"
          >
            {{ t('upgrade') }}
          </deck-button>

          <deck-button
            v-else
            kind="secondary"
            size="small"
            @click="ctaClick"
          >
            {{ t('downgrade') }}
          </deck-button>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script>
import { PLANS } from '~/assets/javascript/constants';
import { mapState } from '~/assets/javascript/modules/vuex';

export default {
  name: 'PricingTable',
  emits: ['ctaClick'],
  setup() {
    return {
      t: useI18n().t,
      ...mapState('workspace', ['workspace']),
    };
  },
  data() {
    return {
      PLANS,
    };
  },
  computed: {
    currentPlan() {
      return PLANS.find(plan => plan.name === this.workspace.plan);
    },
  },
  methods: {
    ctaClick() {
      this.$emit('ctaClick');
    },
  },
};
</script>

<style lang="scss">
.pricing-table {
  table-layout: fixed;
  width: 100%;
}

.pricing-table__plan-text {
  padding-left: 8px;
  width: 150px;
}

.pricing-table__plan-button {
  width: 150px;
}

.pricing-table__price-row {
  background-color: var(--z-theme-background-secondary);
}
</style>
